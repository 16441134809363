<template>
  <v-card min-width="400px">
    <v-toolbar flat dark color="primary">
      <v-card-title>Update Log</v-card-title>
    </v-toolbar>
    <div class="ma-4" v-html="changes" />
    <v-card-actions>
      <v-btn block outlined color="primary" @click="$emit('close')">
        close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['changes']
};
</script>
