<template>
  <v-card rounded="0" flat class="pa-0" width="100%" height="150%">
    <v-toolbar dark flat color="primary">
      <v-row align="center">
        <v-col class="text-left">
          <v-btn icon @click="$emit('close')" v-if="isCloseShow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col>
          <v-toolbar-title class="text-center">Messages</v-toolbar-title>
        </v-col>
        <v-spacer />
        <v-col class="text-right">
          <v-icon x-large @click="newMessage">mdi-plus-circle</v-icon>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-card-text :style="styles" class="overflow-y-auto pt-0 px-0">
      <v-progress-linear
        v-if="conversationsLoading"
        color="primary"
        indeterminate
      />
      <ConversationsList v-else />
    </v-card-text>
  </v-card>
</template>

<script>
import { messages, auth } from '../../sharedPlugin';
import ConversationsList from './ConversationsList.vue';

export default {
  props: ['styles', 'isCloseShow'],
  emits: ['newMessage', 'messageList'],
  components: { ConversationsList },
  computed: {
    ...messages.mapComputed([
      'conversationsLoading',
      'messageGroups',
      'messageGroupsLoading'
    ]),
    ...auth.mapComputed(['user'])
  },
  methods: {
    ...messages.mapMethods(['getConversationsAsync', 'deselectConversation']),
    newMessage() {
      this.deselectConversation();
      this.$emit('newMessage');
    }
  },
  mounted() {
    this.getConversationsAsync();
  }
};
</script>

<style scoped>
.overflow {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-toolbar-title {
  align-self: center;
}

.scrollCol {
  overflow: auto;
}
</style>
