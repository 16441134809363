<template>
  <Messages
    :messages="messages"
    :cols="'auto'"
    :hideToolbar="hideToolbar"
    @submit="addMessage"
    :subject="subject"
    @onBack="handleBackButtonPress"
    @uploadAttachment="uploadAttachment"
  >
    <slot />
  </Messages>
</template>

<script>
import { messages, partner, auth } from '../../sharedPlugin';
import Messages from './Messages.vue';
import { toUploadFormat } from '../../utils/files';

export default {
  props: ['hideToolbar'],
  components: { Messages },

  computed: {
    ...messages.mapComputed(['loading', 'currentConversation', 'messages']),
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner']),
    subject() {
      return this.currentConversation?.subject ?? '';
    }
  },
  methods: {
    ...messages.mapMethods([
      'deselectConversation',
      'addMessageToConversation'
    ]),
    handleBackButtonPress() {
      this.deselectConversation();
      this.$emit('back');
    },
    addMessage(message) {
      let username = this.user?.fullName || null;

      if (
        this.getPartner &&
        this.getPartner.ownerName &&
        this.getPartner.name
      ) {
        let firtNameOwner = this.getPartner.ownerName.split(' ');
        let firtNamePartner = this.getPartner.name.split(' ');
        username = firtNameOwner[0] + ' on behalf of ' + firtNamePartner[0];
      }

      this.addMessageToConversation({ message, document: null, username });
    },

    uploadAttachment(file) {
      let username = this.user?.fullName || null;

      if (
        this.getPartner &&
        this.getPartner.ownerName &&
        this.getPartner.name
      ) {
        let firtNameOwner = this.getPartner.ownerName.split(' ');
        let firtNamePartner = this.getPartner.name.split(' ');
        username = firtNameOwner[0] + ' on behalf of ' + firtNamePartner[0];
      }

      toUploadFormat(file).then((res) => {
        this.addMessageToConversation({
          message: file.name,
          document: res,
          username
        });
      });
    }
  }
};
</script>

<style scoped>
.scrollCol {
  overflow-y: auto;
}
</style>
