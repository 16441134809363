<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-col cols="12">
        <tiptap-vuetify
          v-model="message"
          :extensions="extensions"
          :card-props="{ flat: true }"
          placeholder="Write something …"
          @fileinput="uploadAttachment"
          contentDynamicStyles="height: 100px;"
          style="max-height: 200px; overflow-y: auto; min-height: 200px"
          output-format="json"
        />
        <div style="flex-direction: row-reverse; display: flex">
          <v-btn @click="sendMessage">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  FilePicker,
  ListItem,
  Link,
  Blockquote,
  HardBreak
} from 'tiptap-vuetify';

export default {
  components: {
    TiptapVuetify
  },
  data: () => ({
    message: '',
    file: undefined,
    extensions: []
  }),
  created() {
    this.extensions = [
      Bold,
      Underline,
      Strike,
      Italic,
      ListItem,
      Blockquote,
      Link,
      [
        FilePicker,
        {
          options: {
            onchange: (e) => {
              this.file = e.target.files[0];
              this.$emit('uploadAttachment', this.file);
            }
          }
        }
      ],
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      HardBreak
    ];
  },
  methods: {
    sendMessage() {
      if (this.message != '') {
        this.$emit('submit', JSON.stringify(this.message));
        this.message = '';
      }
    },
    uploadAttachment(file) {
      this.$emit('uploadAttachment', file);
    }
  }
};
</script>
<style lang="scss" scoped>
// .v-form ::v-deep .v-text-field--enclosed .v-input__append-inner {
//   margin-top: 10px;
// }

.text-input {
  width: 240px;
}

.ProseMirror {
  min-height: 100px;
  max-height: 100px;
  overflow: scroll;
}
</style>
