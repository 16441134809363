<template>
  <v-list class="pt-0">
    <v-list-item
      v-for="(conversation, index) in convos"
      :key="index"
      three-line
      @click="itemClicked(conversation)"
      :class="currentIndex === index && 'selected'"
    >
      <v-list-item-content
        :class="{ 'font-weight-bold': conversation.unreadCount > 0 }"
      >
        <v-list-item-title>
          {{ conversation.subject }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ conversation.username ? conversation.username : 'You' }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ conversation.message.replace(/(<([^>]+)>)/gi, '') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text>
          {{ conversation.sentDate.toLocaleString() }}
        </v-list-item-action-text>
        <v-list-item-action-text>
          {{
            conversation.unreadCount > 0
              ? `${conversation.unreadCount} unread messages`
              : ''
          }}
        </v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>
<script>
import { auth, messages, utils } from '../../sharedPlugin';

export default {
  data: () => ({
    currentIndex: null,
    previewMessage: null
  }),
  computed: {
    ...auth.mapComputed(['user']),
    ...messages.mapComputed(['conversations', 'selectedConversation']),
    convos() {
      return [...this.conversations]
        .map((convo) => ({
          ...convo,
          subject:
            this.user.role === 'ehrUser'
              ? `${convo.patientName}: ${convo.subject}`
              : convo.subject,
          username: this.user.userId === convo.userId ? 'You' : convo.username,
          message: this.parseMessage(convo.message)
        }))
        .sort((a, b) => b.sentDate.valueOf() - a.sentDate.valueOf());
    }
  },
  watch: {
    selectedConversation(convoId) {
      if (convoId)
        this.currentIndex = this.conversations.findIndex(
          (convo) => convo.id === convoId
        );
    }
  },
  methods: {
    ...messages.mapMethods(['selectConversationAsync']),
    itemClicked(conversation) {
      this.selectConversationAsync(conversation.id);

      if (this.user.role !== 'patient')
        this.$router
          .replace({ path: `/messages/${conversation.id}` })
          .catch(() => null);
    },
    parseMessage(text) {
      try {
        var tempTxt = JSON.parse(text);
        tempTxt = utils.RtfParsing.getRawText(text);
        return tempTxt;
      } catch {
        return text;
      }
    }
  }
};
</script>
<style scoped>
.selected {
  background-color: #9e969654;
}
</style>
