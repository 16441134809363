<template>
  <v-container class="mainContainer pa-0">
    <v-toolbar v-if="!hideToolbar" dark color="primary" style="flex: 0">
      <v-row align="center">
        <v-col class="text-left">
          <v-btn color="primary" @click="$emit('onBack')">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col>
          <v-toolbar-title class="text-center">{{ subject }}</v-toolbar-title>
        </v-col>
        <v-spacer />
        <v-col />
      </v-row>
    </v-toolbar>
    <v-card ref="messageContainer" style="height: 100%" class="vCardMain" flat>
      <v-row
        v-for="(message, index) in newMessages"
        :key="index"
        ref="messageComponents"
      >
        <Message v-bind:message="message" :cols="cols" />
      </v-row>
    </v-card>
    <v-footer height="auto" class="d-flex flex-grow">
      <slot>
        <RtfTextInput
          style="width: 100%"
          @submit="(message) => $emit('submit', message)"
          @uploadAttachment="(file) => $emit('uploadAttachment', file)"
        />
      </slot>
    </v-footer>
  </v-container>
</template>
<script>
import Message from './Message.vue';
import RtfTextInput from './RtfTextInput.vue';

export default {
  components: { Message, RtfTextInput },
  props: {
    cols: {
      type: String,
      default: null
    },
    messages: { type: Array },
    subject: { type: String },
    hideToolbar: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    newMessages() {
      let newMsgs = [...this.messages].sort((a, b) => a.sentDate - b.sentDate);
      let previousMsg = (newMsgs[0] = {
        ...newMsgs[0],
        nextMessageHourApart: true
      });

      for (let i = 1; i < newMsgs.length; i++) {
        const oneHour = 1000 * 60 * 60;
        let timeDifference =
          newMsgs[i].sentDate.getTime() - previousMsg.sentDate.getTime();
        let currentMsg = {
          ...newMsgs[i],
          nextMessageHourApart: timeDifference > oneHour
        };

        newMsgs[i] = { ...currentMsg };
        previousMsg = currentMsg;
      }
      return newMsgs;
    }
  },
  methods: {
    scrollToBottom() {
      if (this.$refs.messageComponents) {
        const lastComponent =
          this.$refs.messageComponents[this.$refs.messageComponents.length - 1];

        if (lastComponent) {
          this.$vuetify.goTo(lastComponent, {
            container: this.$refs.messageContainer,
            duration: 0
          });
        }
      }
    }
  },
  updated() {
    this.$nextTick(() => this.scrollToBottom());
  }
};
</script>
<style scoped>
.mainContainer {
  height: 150%;
  display: flex;
  flex-direction: column;
}
.vCardMain {
  padding: 0px 10px 0px 10px;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
