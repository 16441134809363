<template>
  <v-col>
    <v-row v-if="message.nextMessageHourApart" justify="center">
      <v-col cols="auto">
        {{ sentDate }}
      </v-col>
    </v-row>
    <v-row :justify="message.userId === user.userId ? 'end' : 'start'">
      <v-col :cols="cols">
        <v-tooltip top :disabled="message.nextMessageHourApart">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              rounded="xl"
              :color="messageColor"
              :dark="isMessageDark"
              flat
              v-bind="attrs"
              v-on="on"
            >
              <v-card-subtitle>
                <b>{{ username }}</b>
              </v-card-subtitle>
              <v-card-text v-if="noAttachment">
                <TiptapVuetify
                  :disabled="true"
                  :extensions="extensions"
                  :card-props="{
                    flat: true,
                    color:
                      message.userId === user.userId ? 'primary' : '#d3d3d3'
                  }"
                  style="margin-bottom: -16px"
                  v-model="text"
                  output-format="json"
                >
                  <template #toolbar="{ buttons }">
                    <pre>{{ buttons }}</pre>
                  </template>
                </TiptapVuetify>
              </v-card-text>
              <v-card-actions v-else>
                <v-btn
                  class="downloadButton"
                  outlined
                  color="primary"
                  @click="downloadAttachment"
                >
                  {{ text }}
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
          <span>{{ sentDate }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { auth, api, messages } from '../../sharedPlugin';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify';

export default {
  props: ['message', 'cols'],
  components: { TiptapVuetify },
  data: () => ({
    extensions: []
  }),
  computed: {
    ...auth.mapComputed(['user']),
    ...messages.mapComputed(['selectedConversation']),
    sentDate() {
      return this.message.sentDate?.toLocaleString() ?? '';
    },

    noAttachment() {
      if (this.message.documentId != null) {
        return false;
      } else {
        return true;
      }
    },
    messageColor() {
      return this.message.userId === this.user.userId ? 'primary' : '	#D3D3D3';
    },
    isMessageDark() {
      return this.message.userId === this.user.userId;
    },
    text() {
      if (!this.noAttachment) {
        var msg = this.message.message.split('.');
        var name = msg[0];
        if (name.length >= 40) {
          var half = name.length;
          half = half / 2;
          var newName = name.substring(0, half);
          newName = newName + '...' + msg[1];
          return newName;
        }
      }
      //will need a try catch, if this is a json string that can be parsed, parse it and get the message.
      //if not, it was sent before we had rtf support, and must be displayed properly
      try {
        var tempTxt = JSON.parse(this.message.message);
        return tempTxt;
      } catch {
        return this.message.message;
      }
    },
    username() {
      return this.message.username ? this.message.username : 'You';
    }
  },
  methods: {
    downloadAttachment() {
      var fileName = this.evaluateFileExt(this.message.message);

      api.Messages.getAttachment({
        conversationId: this.selectedConversation,
        messageId: this.message.id
      }).then((res) => {
        const arrayBuffer = this.base64ToArrayBuffer(res.data[0].document);
        this.download(arrayBuffer, fileName);
      });
    },
    download(arrayBuffer, fileName) {
      const blob = new Blob([arrayBuffer], {
        type: 'application/octet-stream'
      });

      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
    },
    base64ToArrayBuffer(data) {
      const binaryString = atob(data);
      const bytes = new Uint8Array(binaryString.length);
      return bytes.map((byte, i) => binaryString.charCodeAt(i));
    },
    evaluateFileExt(fileName) {
      var file = fileName.split('.');
      fileName = file[0];
      var fileExt = file[1];
      if (fileExt === 'docx') fileExt = 'doc';
      fileName = fileName + '.' + fileExt;

      return fileName;
    }
  },
  created() {
    this.extensions = [
      Bold,
      Underline,
      Strike,
      Italic,
      ListItem,
      Blockquote,
      Link,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      HorizontalRule,
      Paragraph,
      HardBreak
    ];
  }
};
</script>

<style scoped>
.theme--light.v-card {
  background-color: #d3d3d3;
}
:root .theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: black;
  --v-anchor-base: #024bb9;
}
:root .theme--dark.v-card > .v-card__text,
.theme--dark.v-card .v-card__subtitle {
  color: white;
  --v-anchor-base: #75caf6;
}
.downloadButton {
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
}
</style>
